import { Box, Card, CardContent, Chip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { NEGRO, BLANCO, GRIS_CLARO, VERDE } from '../../../../constants/colores'
import AccessTimeIcon from '@material-ui/icons/AccessTime';
// import { relojGeneralAtom } from '../../../../recoil/atoms/reloj/relojAtom'
// import { useRecoilState } from 'recoil';

const useStyles = makeStyles({
    card: {
        backgroundColor: NEGRO
    },
    titulo: {
        color: BLANCO
    },
    tiempoEstimadoBox: {
        justifyContent: "flex-end"
    },
    tiempoEstimado: {
        color: VERDE
    },
    tiempoEstimadoIcon: {
        color: VERDE,
        marginRight: ".3rem"
    },
    explicacion: {
        color: GRIS_CLARO
    },
    equipoBox: {
        justifyContent: "flex-end"
    },
    equipo: {
        color: VERDE,
        borderColor: VERDE
    }
  });

  
function CardSesion({sesion}) {
    const classes = useStyles();

    // const [relojGeneral,setRelojGeneral] = useRecoilState(relojGeneralAtom)

    const handleClick = () => {
        // setRelojGeneral({
        //     sesion_id: sesion.id,
        //     tiempo: 0
        // })
    }
    
    return (
        <Box mb={2}>
            <Link style={{ textDecoration: 'none' }} onClick={handleClick} to={`/sesion/${sesion.id}`}>
                <Card className={classes.card}  >
                    
                    <CardContent>
                        <Box display="flex" className={classes.tiempoEstimadoBox}>
                            <AccessTimeIcon className={classes.tiempoEstimadoIcon}/>
                            <Typography className={classes.tiempoEstimado} gutterBottom>{sesion.min_total_estimado} min</Typography>
                        </Box>
                        <Typography variant="h6" className={classes.titulo}>{sesion.nombre}</Typography>
                        { 
                            sesion.explicacion ?
                            <Typography className={classes.explicacion} gutterBottom>{sesion.explicacion}</Typography>
                            :
                            <></>
                        }

                        <Box display="flex" className={classes.equipoBox}>
                            <Box mt={1}>
                            {
                                (sesion.equipo ?
                                    (
                                        sesion.equipo.split(",").map( (item) => {
                                            return (
                                                <Chip key={item} size="small" variant="outlined" className={classes.equipo} label={item} />
                                            )
                                        } )
                                    )
                                :
                                    <></>

                                )
                            }
                            </Box>
                            
                        </Box>
                    </CardContent>
                </Card>
            </Link>
        </Box>
    )
}

export default CardSesion

import { useEffect, useState } from "react";
import getSesionDetalle from "../services/getSesionDetalle";

const  useSesion = (sesion_id) => {
    const [sesiones, setSesiones] = useState([])
    const [loading, setLoading] = useState(false)

  useEffect(() => {
    
    const fetchData = async (sesion_id) => {
        setLoading(true)
      
        getSesionDetalle(sesion_id).then((res)=>{ 
          
          let ejercicios = []
          res.data.ejercicios.forEach(item => {
            for (let index = 0; index < item.num_series; index++) {
              let elemento = {
                ...item,
                orden_repeticion: index + 1
              }

              ejercicios = [...ejercicios,elemento]
            }
          });
          setSesiones(ejercicios);
          setLoading(false)

        }
      );
  
    }

    fetchData(sesion_id)
  }, [sesion_id])
  
    return [ sesiones,setSesiones, loading ]
}

export default useSesion;
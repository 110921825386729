import React from 'react'
import { selectRelojGeneral } from '../../redux/reducers/relojGeneralReducer'
import { useSelector,useDispatch } from 'react-redux'
import useInterval from '../../hooks/useInterval'
import { relojGeneralActionIncrement } from '../../redux/actions/relojGeneral'

function RelojGeneralIncrement() {
    const relojGeneral = useSelector(selectRelojGeneral);
    const dispatch = useDispatch()

    useInterval(() => {
        dispatch(relojGeneralActionIncrement())
    }, 1000);

    return null
}

export default RelojGeneralIncrement

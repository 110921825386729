import { Container, Typography, Box, CircularProgress } from '@material-ui/core'
import Cargando from '../../components/Cargando';
// import { useEffect } from 'react'
import HSAppBar from '../../components/HSAppBar';
import useSesion from '../../hooks/useSesion';
import CardSesion from './components/CardSesion';
// import sendSesion from '../../services/sendSesion'
// import { finalizarSesionAtom } from '../../recoil/atoms/sesionAtom';

function Home() {
  const [sesiones, useSesiones, loading] = useSesion();

    // const [relojGeneral, setRelojGeneral] = useRecoilState(relojGeneralAtom)
    // const [finalizarSesion, setFinalizarSesion] = useRecoilState(finalizarSesionAtom)

	
    // useEffect(() => {
    //   if (finalizarSesion === true) {
    //     setFinalizarSesion(false)
    //   }
    // }, [finalizarSesion,setFinalizarSesion])
    
    // useEffect(() => {
    //   if (relojGeneral.sesion_id !== 0) {
    //     sendSesion(relojGeneral.sesion_id, relojGeneral.tiempo)
    //     setRelojGeneral({
    //       sesion_id: 0,
    //       tiempo: 0
    //     })
    //   }
    // }, [relojGeneral,setRelojGeneral])
    
    const Contenido = () => {
      return (
        <>
          

          {
            ( 
              sesiones ? 
              sesiones.map( (sesion) => {
                return (
                  <CardSesion key={sesion.id} sesion={sesion}/>
                )
              } 
              ) : 
              '' 
            )
          }
        </>
      )
    }
    return (
      <>
          <HSAppBar enlaceDerecho="/historico"/>
          <Container maxWidth="md">
            <Box my={3}>
              <Typography variant="h5">Entrenamientos</Typography>
            </Box>

            {
              loading ?
                <Cargando />
              :
              <Contenido />
            }

          </Container>
        
      </>
    )
}

export default Home

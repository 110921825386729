import React from 'react'
import { selectRelojEjercicio } from '../../redux/reducers/relojEjercicioReducer'
import { useSelector,useDispatch } from 'react-redux'
import useInterval from '../../hooks/useInterval'
import { relojEjercicioActionIncrement } from '../../redux/actions/relojEjercicio'

function RelojEjercicioIncrement() {
    const relojEjercicio = useSelector(selectRelojEjercicio);
    const dispatch = useDispatch()

    useInterval(() => {
        dispatch(relojEjercicioActionIncrement())
    }, 1000);

    return null
}

export default RelojEjercicioIncrement

import axios from 'axios';
import {
    BASE_URL_SERVICE,
} from '../constants'


async function sendSesion (sesion_id, segundos) {
    
    try {
        var config = {
        	headers: { 
        		'content-type': 'application/x-www-form-urlencoded',
        		// 'content-type': 'application/form-data'
        	}
        };
        // var config = {
        //     headers: { 
        //         'Access-Control-Allow-Origin': '*',
        //         'content-type': 'text/json'
        //     }
        //     };

        var params = new URLSearchParams();
        params.append('sesion_id', sesion_id);
        params.append('segundos', segundos.toString());
        const response = await axios.post( BASE_URL_SERVICE + '/sesiones', params, config);

        return response;
    } catch (error) {
        throw error;
        // return undefined;
    }

}

export default sendSesion;
import { Box, AppBar, Toolbar, Theme, Chip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import HSAppBar from '../../components/HSAppBar';
import useSesionDetalle from '../../hooks/useSesionDetalle';
import Detalle from './components/Detalle';
import {useDispatch} from 'react-redux'
import { finalizarSesion, iniciarSesion } from '../../redux/actions/relojGeneral'
import { iniciarEjercicio } from '../../redux/actions/relojEjercicio'

// import { useRecoilState } from 'recoil';
// import { resetRelojEjercicioAtom } from '../../recoil/atoms/reloj/relojAtom'
// import { finalizarSesionAtom  } from '../../recoil/atoms/sesionAtom'

import { BLANCO, VERDE } from '../../constants/colores'
import Cargando from '../../components/Cargando';
// import { Link } from 'react-router-dom';
// import sendSesion from '../../services/sendSesion';

// interface RouteParams {
//     id: string
//   }
 
function SesionDetalle() {
    let {id} = useParams();

    const sesion_id = id;

    const [sesionDetalle, setSesionDetalle, loading] = useSesionDetalle(sesion_id)
    const [indexEjercicioActual, setIndexEjercicioActual] = useState(0)
    const [ejercicioActual, setEjercicioActual] = useState({})
    const [totalEjercicios, setTotalEjercicios] = useState(0)
    const [volverHome, setVolverHome] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(false);

    const dispatch = useDispatch()

    // const [finalizarSesion, setFinalizarSesion] = useRecoilState(finalizarSesionAtom);
    // const [resetRelojEjercicio, setResetRelojEjercicio] = useRecoilState(resetRelojEjercicioAtom);


    useEffect(() => {
      dispatch(iniciarSesion(sesion_id))
    }, [])

    useEffect(() => {
      const ejercicios = JSON.parse( JSON.stringify(sesionDetalle,null,2) )
      
      setTotalEjercicios(ejercicios.length)
      setEjercicioActual(ejercicios[indexEjercicioActual])
      
      dispatch(iniciarEjercicio(ejercicios[indexEjercicioActual]?.id))
      
    }, [sesionDetalle, indexEjercicioActual])


    const handleSiguienteEjercicio = () => {
      if(indexEjercicioActual < sesionDetalle.length - 1) {
        setIndexEjercicioActual(indexEjercicioActual + 1)
      } else {
        setOpenConfirm(false);
        dispatch(finalizarSesion())
        setVolverHome(true)
      }    
    }
    
    const handleAnteriorEjercicio = () => {
      if(indexEjercicioActual > 0) {
        setIndexEjercicioActual(indexEjercicioActual - 1) 
      }
    }

    
    const useStyles = makeStyles((theme) =>
        createStyles({
            appBar: {
                backgroundColor: BLANCO,
                top: 'auto',
                bottom: 0,
            },
            chipDireccion: {
              color: BLANCO,
              backgroundColor: VERDE,
              "&:hover, &:focus": {
                backgroundColor: VERDE
              },
              "&:active": {
                backgroundColor: VERDE
              }
            },
        })
    );
    const classes = useStyles();
 
    const handleClickOpenConfirm = () => {
      setOpenConfirm(true);
    };
  
    const handleCloseConfirm = () => {
      setOpenConfirm(false);
    };

    const Contenido = () => {
      return (
        
          ejercicioActual ? 
          <Detalle 
            ejercicio={ejercicioActual} 
            indexEjercicioActual={indexEjercicioActual + 1} 
            totalEjercicios={totalEjercicios}
          />
          :
          <></>
        
      )
    }
    
    return (
      <>
        {
          volverHome ===true ?
          <Redirect to='/historico' />
          :
          <></>
        }

        <HSAppBar enlaceIzquierdo="/" />

        {
          loading ? 
          <Cargando />
          :
          <Contenido />
        }

        <AppBar position="fixed" color="primary" className={classes.appBar}>
            <Toolbar>
              <Box display="flex" justifyContent="space-between" style={{width: '100%'}}>
                <Chip className={classes.chipDireccion} label="< ANTERIOR" clickable={true} onClick={handleAnteriorEjercicio} disabled={indexEjercicioActual === 0}/>
                
                {
                  (indexEjercicioActual+1) === totalEjercicios ?
                  <>
                  {/* <Chip className={classes.chipDireccion} label="FINALIZAR" clickable={true} color="primary" component={Link} to="/" /> */}
                  <Chip className={classes.chipDireccion} label="FINALIZAR" clickable={true} color="primary" onClick={handleClickOpenConfirm} />
                  </>
                  :
                  <Chip className={classes.chipDireccion} label="SIGUIENTE >" clickable={true} color="primary" onClick={handleSiguienteEjercicio} />
                }
              </Box>
            </Toolbar>
        </AppBar>

        <Dialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¿Guardar los datos?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Deseas guardar los datos de tu sesión?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirm} color="primary">
            No
          </Button>
          <Button onClick={handleSiguienteEjercicio} color="primary" autoFocus>
            Si
          </Button>
        </DialogActions>
      </Dialog>
      </>        
    )
}

export default SesionDetalle
import { Box, CircularProgress } from '@material-ui/core'
import React from 'react'

function Cargando() {
    return (
        <Box display="flex" justifyContent="center" mt={5}>
            <CircularProgress style={{'color': '#ADC852'}} />
        </Box>
    )
}

export default Cargando

import { Box, Button, Chip, Container, createStyles, MobileStepper, Theme, Typography, useTheme } from '@material-ui/core'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React from 'react'
import { GRIS_CLARO, VERDE, NEGRO } from '../../../../constants/colores'
// import Hora from '../../../../components/Hora/Hora'
import ReactPlayer from 'react-player';

import HoraGeneral from '../../../../components/Hora/HoraGeneral'
import HoraEjercicio from '../../../../components/Hora/HoraEjercicio'


function Detalle({ejercicio, indexEjercicioActual, totalEjercicios, /*relojGeneral, relojEjercicio*/}) {
    const {nombre, imagenes, repeticiones, tiempo, tiempo_descanso, descripcion, videos, orden_repeticion,num_series} = ejercicio
    // const maxSteps = 3 imagenes.length
    const maxStepsImagenes = (imagenes ? imagenes.length : 0 )
    const [activeStepImagenes, setActiveStepImagenes] = React.useState(0);
    const [verVideo, setVerVideo] = React.useState(false)
    // const maxStepsVideos = (videos ? videos.length : 0 )
    const [activeStepVideos, setActiveStepVideos] = React.useState(0);
    
    const handleNextImagenes = () => {
        setActiveStepImagenes((prevActiveStep) => prevActiveStep + 1);
    };
    
    const handleBackImagenes = () => {
        setActiveStepImagenes((prevActiveStep) => prevActiveStep - 1);
    };

    const handleViewVideo = () => {
        setVerVideo( !verVideo )
    }

    const useStyles = makeStyles((theme) =>
        createStyles({
            img: {
                //height: 255,
                maxWidth: 400,
                overflow: 'hidden',
                display: 'block',
                width: '100%',
            },
            chipIndice: {
                borderColor: NEGRO,
                color: NEGRO,
            },
        })
    );

    const classes = useStyles();
    // const theme = useTheme();

    const SliderImagenes = () => {
        return (
            <>
            {   
                imagenes && imagenes[activeStepImagenes]? 
                <img
                    className={classes.img}
                    src={imagenes[activeStepImagenes].ruta}
                    alt={imagenes[activeStepImagenes].alt}
                />
                : 
                <></>
            }

            { 
                maxStepsImagenes > 1 ?
                <MobileStepper
                    steps={maxStepsImagenes}
                    position="static"
                    variant="text"
                    activeStep={activeStepImagenes}
                    nextButton={
                        <Button size="small" onClick={handleNextImagenes} disabled={activeStepImagenes === maxStepsImagenes - 1}>
                            <KeyboardArrowRight />
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBackImagenes} disabled={activeStepImagenes === 0}>
                            <KeyboardArrowLeft />
                            
                        </Button>
                    }
                />
                :
                <></>
            }
            </>
        )
    }

    const SliderVideos = () => {
        return (
            <>
            {                  
                videos && videos[activeStepVideos]? 
                <ReactPlayer url={videos[activeStepVideos]?.ruta} playing width="100%"/>
                : 
                <></>
            }
            </>
        )
    }

    return (
        <>
        <Container maxWidth="md">
            <Box mt={3} mb={1} display="flex" justifyContent="space-between">
                <Typography variant="h5">{nombre} ({orden_repeticion}/{num_series})</Typography>
                <Chip className={classes.chipIndice} variant="outlined" label={` ${indexEjercicioActual} / ${totalEjercicios} `} />
            </Box>
            <Box display="flex" justifyContent="space-between" mb={1}>
                { 
                    videos && videos.length > 0 ?
                    <Chip variant="outlined" onClick={ handleViewVideo } label={ (verVideo === true ? 'Ver imágenes' : 'Ver video') } />
                    :
                    <Chip variant="outlined" disabled label={ (verVideo === true ? 'Ver imágenes' : 'Ver video') } />
                }

                <Box display="flex" justifyContent="flex-end" alignItems="center">
                    <HoraGeneral />
                    <Typography variant="body2" style={{marginLeft: '5px', marginRight: '5px'}}>-</Typography> 
                    <HoraEjercicio />
                </Box>
            </Box>
            
            {
                verVideo === true ?
                <SliderVideos />
                :
                <SliderImagenes />
            }

            <Box mt={2}>
                {
                    repeticiones > 0 ?
                        
                        <Chip variant="outlined" label={ `${repeticiones} repetisiones` } />
                        :
                        <Chip variant="outlined" label={ `${tiempo} segundos` } />
                        
                }
                <Chip variant="outlined" label={ `Descanso: ${tiempo_descanso} segundos`} style={{marginLeft: '0.5rem'}} />
            </Box>

            

            <Box mt={2} mb={10}>
                <Typography variant="body2">{descripcion}</Typography>
            </Box>

        </Container>
        
        {/* <br />
        <br />
        <br />
        <code>
        <pre>
            {JSON.stringify(ejercicio,null,2)}  
        </pre>
        </code> */}
        </>
    )
}

export default Detalle

import axios from 'axios';
import {
    BASE_URL_SERVICE,
} from '../constants'
	
async function getSesionDetalle (sesion_id) {
    try {
        var config = {
        	headers: { 
        		// 'content-type': 'application/x-www-form-urlencoded'
        		'content-type': 'application/form-data'
        	}
        };
        // var config = {
        //     headers: { 
        //         'Access-Control-Allow-Origin': '*',
        //         'content-type': 'text/json'
        //     }
        //     };

        var params = new URLSearchParams();
        params.append('sesion_id', sesion_id.toString());
        const response = await axios.get( BASE_URL_SERVICE + '/ejercicios?sesion_id='+sesion_id, config);

        return response;
    } catch (error) {
        return undefined;
        // throw error;
    }

}

export default getSesionDetalle;
import React from 'react'
import Hora from './Hora'
import { useSelector } from 'react-redux'
import { selectRelojEjercicio } from '../../redux/reducers/relojEjercicioReducer'

function HoraEjercicio() {
    const relojEjercicio = useSelector(selectRelojEjercicio);

    
    return (
        <Hora segundos={relojEjercicio.segundos} />
    )
}

export default HoraEjercicio

import sendSesion from '../../services/sendSesion'

// import axios from "axios";

//import { selectUsers } from "../reducer/users";
//import { usersSuccess, usersSelectOne } from "./users";
// import { selectPosts } from "../reducer/postsReducer";

// ----------------------------------------------------
// Tipos de acciones
const RELOJGENERAL_INCREMENT = "RELOJGENERAL_INCREMENT";
const RELOJGENERAL_RESET = "RELOJGENERAL_RESET";
const RELOJGENERAL_INICIAR_SESION = "RELOJGENERAL_INICIAR_SESION";

const relojTypes = {
    RELOJGENERAL_INCREMENT,
    RELOJGENERAL_RESET,
    RELOJGENERAL_INICIAR_SESION
};

// ----------------------------------------------------
// Creadores de acciones
function relojGeneralActionIncrement() {
    return { type: RELOJGENERAL_INCREMENT };
}
function relojGeneralActionReset() {
    return { type: RELOJGENERAL_RESET };
}
function relojGeneralActionIniciarSesion(payload) {
    return { type: RELOJGENERAL_INICIAR_SESION, payload };
}

function finalizarSesion() {
    return async function thunk(dispatch, getState) {
        const {relojGeneral} = getState();
        console.log(relojGeneral);
        sendSesion(relojGeneral.sesion_id, relojGeneral.segundos)
        dispatch(relojGeneralActionReset())
    }
}

function iniciarSesion(sesion_id) {
    return async function thunk(dispatch) {
        dispatch(relojGeneralActionIniciarSesion(sesion_id))
    }
}
// function getRelojGeneral() {
//     return async function thunk(dispatch) {
//     dispatch(postsRequest());
//     try {
//         const { data } = await axios({
//         url: "https://jsonplaceholder.typicode.com/posts",
//         method: "GET"
//         });
//         dispatch(postsSuccess(data));
//     } catch (error) {
//         dispatch(postsError(error));
//     }
//     };
// }

// function getPostsByUser(userId) {
//   return async function thunk(dispatch, getState) {
//     dispatch(postsRequest());
//     try {
//       const users = selectUsers(getState());
//       const posts = selectPosts(getState());
//       const { data } = await axios({
//         url: `https://jsonplaceholder.typicode.com/posts?userId=${userId}`,
//         method: "GET"
//       });

//       const updatedPosts = [...posts, data];
//       let newSelectedUser;
//       const updatedUsers = users.map(user => {
//         if (user.id === userId) {
//           newSelectedUser = {
//             ...user,
//             postsKey: updatedPosts.length - 1
//           };
//           return newSelectedUser;
//         }
//         return user;
//       });

//       dispatch(usersSuccess(updatedUsers));
//       dispatch(postsSuccess(updatedPosts));
//       dispatch(usersSelectOne(newSelectedUser));
//     } catch (error) {
//       dispatch(postsError("Error loading posts"));
//     }
//   };
// }

export {
//   getPostsByUser,
    relojGeneralActionIncrement,
    relojGeneralActionReset,
    relojGeneralActionIniciarSesion,
    finalizarSesion,
    iniciarSesion,
    relojTypes
};
import { useEffect, useState } from "react";
import getHistorico from "../services/getHistorico";

const  useHistorico = () => {
    const [historico, setHistorico] = useState([])
    const [loading, setLoading] = useState(false)

  useEffect(() => {
    
    const fetchData = async () => {
      setLoading(true)
        getHistorico().then((res)=>{ 
          setHistorico(res.data.historico);
          // console.log(res.data);
          setLoading(false)
        }
      );
  
    }

    fetchData()
  }, [])
  
    return [ historico, setHistorico, loading ]
}

export default useHistorico;
import { Box, Container, Divider, List, ListItem, ListItemText, makeStyles, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import Cargando from '../../components/Cargando';
import useHistorico from '../../hooks/useHistorico'
import HSAppBar from './../../components/HSAppBar'

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  }));

function Historico() {
    const [historico, setHistorico, loading] = useHistorico();
    const classes = useStyles();

    const Contenido = () => {
        return (
            <Box>
                <List dense={true} className={classes.root} >
                    {
                        historico.map( item => {
                            return (
                                <>
                                <ListItem>
                                    <ListItemText primary={item.sesion_nombre} secondary={item.fecha}/>
                                    <Box edge="end">
                                        <ListItemText primary={`${item.minutos} min`}/>
                                    </Box>
                                </ListItem>
                                <Divider light />
                                </>
                            )
                        })
                    }
                </List>
            </Box>
        )
    }
    return (
        <>
            <HSAppBar enlaceIzquierdo="/" />   

            <Container maxWidth="md">
                <Box mt={3} mb={2}>
                    <Typography variant="h5">Histórico</Typography>
                </Box>
                
                {
                    loading ?
                    <Cargando />
                    :
                    <Contenido />
                }

            </Container>
        </>
    )
}
export default Historico
    
import {
  BrowserRouter,
  Route,
  Switch,
//   // Redirect
} from 'react-router-dom';
  
  import NotFound from './pages/NotFound';
  import Home from './pages/Home';
  import Historico from './pages/Historico'
  
  import useSesion from './hooks/useSesion';
  import SesionDetalle from './pages/SesionDetalle';
  // import SesionResumen from './pages/SesionResumen';
  
  // function PrivateRoute ({component: Component, authed, ...rest}) {
  //   return (
  //     <Route
  //       {...rest}
  //       render={(props) => authed === true
  //         ? <Component {...props} />
  //         : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
  //     />
  //   )
  // }
  
  // const RouteAbout = () => {
  //   return (
  //     <Route path="/about">
  //       About page
  //     </Route>
  //   );
  // }
  // const RouteHome = () => {
  //   return (
  //     <Route component={Home} path="/home"></Route>
  //   );
  // }
  
  
  // const useStyles = makeStyles({
  //   root: {
      
  //   },
  // });
  
  // function App() {
  //   const classes = useStyles();
  
  //   return (
  //     <Container maxWidth="md" className={classes.root}>
  //       <Box my={4}>
  //         <Typography variant="h4" component="h1" gutterBottom>
  //           HomeStart
  //         </Typography>
  //       </Box>
  
  //       <Box>
  
  //       </Box>
  //     </Container>
  //   );
  // }
  
  export default function App(props) {
    // const usuarioSeleccionado = useSelector(state => {
    //   return selectUsuarioSeleccionado(state);
    // });
    // const [sesiones/*, useSesiones*/] = useSesion();
  
    return ( 
      <BrowserRouter> 
        <Switch>
          
          {/* ZONA PUBLICA */}
          {/* <Route component={Login} path="/login"></Route> */}
          {/* <Route component={SesionDetalle} path="/sesion/:id"></Route>
          <Route component={SesionResumen} path="/sesion2/:id"></Route>
   */}
          <Route component={SesionDetalle} path="/sesion/:id"></Route>
          <Route component={Historico} path="/historico"></Route>
          <Route exact path="/">
            <Home />
          </Route>
  
          {/* ZONA PRIVADA */}
          {/* <PrivateRoute authed={ (usuarioSeleccionado ? true : false) } path='/about' component={RouteAbout} /> */}
          {/* <PrivateRoute authed={ (usuarioSeleccionado ? true : false) } path='/home' component={RouteHome} /> */}
          
          {/* PAGINA NO ENCONTRADA */}
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </BrowserRouter>
  
    )
  } 
  
  
  
  
  
  
    
  
    
    
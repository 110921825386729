// import axios from "axios";

//import { selectUsers } from "../reducer/users";
//import { usersSuccess, usersSelectOne } from "./users";
// import { selectPosts } from "../reducer/postsReducer";

// ----------------------------------------------------
// Tipos de acciones
const RELOJEJERCICIO_INCREMENT = "RELOJEJERCICIO_INCREMENT";
const RELOJEJERCICIO_RESET = "RELOJEJERCICIO_RESET";
const RELOJEJERCICIO_INICIAR = "RELOJEJERCICIO_INICIAR";

const relojTypes = {
    RELOJEJERCICIO_INCREMENT,
    RELOJEJERCICIO_RESET,
    RELOJEJERCICIO_INICIAR
};

// ----------------------------------------------------
// Creadores de acciones
function relojEjercicioActionIncrement() {
    return { type: RELOJEJERCICIO_INCREMENT };
}
function relojEjercicioActionReset() {
    return { type: RELOJEJERCICIO_RESET };
}
function relojEjercicioActionIniciar(payload) {
    return { type: RELOJEJERCICIO_INICIAR, payload };
}

// function finalizarSesion() {
//     return async function thunk(dispatch) {
//         dispatch(relojEjercicioActionReset())
//     }
// }

function iniciarEjercicio(ejercicio_id) {
    return async function thunk(dispatch) {
        dispatch(relojEjercicioActionIniciar(ejercicio_id))
    }
}
// function getRelojEjercicio() {
//     return async function thunk(dispatch) {
//     dispatch(postsRequest());
//     try {
//         const { data } = await axios({
//         url: "https://jsonplaceholder.typicode.com/posts",
//         method: "GET"
//         });
//         dispatch(postsSuccess(data));
//     } catch (error) {
//         dispatch(postsError(error));
//     }
//     };
// }

// function getPostsByUser(userId) {
//   return async function thunk(dispatch, getState) {
//     dispatch(postsRequest());
//     try {
//       const users = selectUsers(getState());
//       const posts = selectPosts(getState());
//       const { data } = await axios({
//         url: `https://jsonplaceholder.typicode.com/posts?userId=${userId}`,
//         method: "GET"
//       });

//       const updatedPosts = [...posts, data];
//       let newSelectedUser;
//       const updatedUsers = users.map(user => {
//         if (user.id === userId) {
//           newSelectedUser = {
//             ...user,
//             postsKey: updatedPosts.length - 1
//           };
//           return newSelectedUser;
//         }
//         return user;
//       });

//       dispatch(usersSuccess(updatedUsers));
//       dispatch(postsSuccess(updatedPosts));
//       dispatch(usersSelectOne(newSelectedUser));
//     } catch (error) {
//       dispatch(postsError("Error loading posts"));
//     }
//   };
// }

export {
//   getPostsByUser,
    relojEjercicioActionIncrement,
    relojEjercicioActionReset,
    relojEjercicioActionIniciar,
    iniciarEjercicio,
    relojTypes
};
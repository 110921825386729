import { useEffect, useState } from "react";
import getSesion from "../services/getSesion";

const  useSesion = () => {
  const [sesiones, setSesiones] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      console.log(loading)
        getSesion().then((res)=>{ 
          setSesiones(res.data.sesiones);
          // console.log(loading)
          setLoading(false)
        }
      );
  
    }

    fetchData()
  }, [])
  
    return [ sesiones,setSesiones, loading ]
}

export default useSesion;
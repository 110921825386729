import { AppBar, Box, Grid, IconButton, Toolbar, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { Link } from 'react-router-dom'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AccountCircle from '@material-ui/icons/AccountCircle';

import { NEGRO, VERDE } from '../../constants/colores'

const useStyles = makeStyles(theme => ({
    offset: theme.mixins.toolbar,
    root: {
        flexGrow: 1,
    },
    appbar: {
        // alignItems: 'center',
        backgroundColor: NEGRO,
    },
    titulo1: {
        fontWeight: 900,
        padding: '8px 0',
    },
    titulo2: {
        fontWeight: 900,
        color: VERDE,
        padding: '8px 0',
    },
    menuButton: {}
}))

function HSAppBar(props) {
    const { enlaceIzquierdo = '', enlaceDerecho = '' } = props
    const classes = useStyles();
    const menuId = 'primary-search-account-menu';
    return (
        <>
        <div className={classes.root}>
            <AppBar className={classes.appbar}>
                <Toolbar variant="dense">
                    <Grid justify={"space-between"} container>
                        <Grid xs={1} item>
                            { 
                                enlaceIzquierdo ? 
                                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" component={Link} to={enlaceIzquierdo}>
                                    <ArrowBackIosIcon />
                                </IconButton>
                                : 
                                <></> 
                            }
                        </Grid>
                        <Grid xs={4} item>
                            <Grid container justify={"center"}>
                                <Box display="flex" alignItems="center">
                                    <Typography variant="h5" color="inherit" className={classes.titulo1}>
                                        Home
                                    </Typography>
                                    <Typography variant="h5" color="inherit" className={classes.titulo2}>
                                        Sport
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid xs={1} item>
                            { 
                                enlaceDerecho ? 

                                <IconButton edge="end" className={classes.menuButton} color="inherit" aria-haspopup="true" aria-label="menu" component={Link} to={enlaceDerecho}>
                                    <AccountCircle />
                                </IconButton>
                                : 
                                <></> 
                            }
                        </Grid>
                    </Grid>                    
                </Toolbar>
            </AppBar>
            </div>
            <div className={classes.offset} />
        </>
    )
}

export default HSAppBar

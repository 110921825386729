import { relojTypes } from "../actions/relojGeneral";
import { createSelector } from "reselect";

const initialState = {
  segundos: 0,
  sesion_id: 0
};

const { RELOJGENERAL_INCREMENT, RELOJGENERAL_RESET, RELOJGENERAL_INICIAR_SESION } = relojTypes;

function relojGeneralReducer(state = initialState, action) {
  switch (action.type) {
    case RELOJGENERAL_INCREMENT:
      return {...state, segundos: state.segundos + 1}
    // case RELOJGENERAL_RESET:
    //   return { segundos: 0, sesion_id: 0 };
    case RELOJGENERAL_INICIAR_SESION:
      return { segundos: 0, sesion_id: action.payload };
    default:
      return state;
  }
}

const selectRelojGeneral = createSelector(
  state => state.relojGeneral,
  relojGeneral => relojGeneral
);

// function selectRelojGeneral(state) {
//   return state.relojGejeral;
// }

export default relojGeneralReducer;

export {
    // selectPosts,
    selectRelojGeneral,
};

import axios from 'axios';
import {
    BASE_URL_SERVICE,
} from '../constants'
	
async function getHistorico () {
    try {
        var config = {
        	headers: { 
        		// 'content-type': 'application/x-www-form-urlencoded'
        		'content-type': 'application/form-data'
        	}
        };
        // var config = {
        //     headers: { 
        //         'Access-Control-Allow-Origin': '*',
        //         'content-type': 'text/json'
        //     }
        //     };

        // var params = new URLSearchParams();
        // params.append('frecuencia', frecuencia);
        const response = await axios.get( BASE_URL_SERVICE + '/historico', config);

        return response;
    } catch (error) {
        return undefined;
        // throw error;
    }

}

export default getHistorico;
import { relojTypes } from "../actions/relojEjercicio";
import { createSelector } from "reselect";

const initialState = {
  segundos: 0,
  ejercicio_id: 0
};

const { RELOJEJERCICIO_INCREMENT, RELOJEJERCICIO_RESET, RELOJEJERCICIO_INICIAR } = relojTypes;

function relojEjercicioReducer(state = initialState, action) {
  switch (action.type) {
    case RELOJEJERCICIO_INCREMENT:
      return {...state, segundos: state.segundos + 1}
    case RELOJEJERCICIO_RESET:
      return { segundos: 0, ejercicio_id: 0 };
    case RELOJEJERCICIO_INICIAR:
      return { segundos: 0, ejercicio_id: action.payload };
    default:
      return state;
  }
}

const selectRelojEjercicio = createSelector(
  state => state.relojEjercicio,
  relojEjercicio => relojEjercicio
);

// function selectRelojEjercicio(state) {
//   return state.relojGejeral;
// }

export default relojEjercicioReducer;

export {
    // selectPosts,
    selectRelojEjercicio,
};

import React from 'react'
import Hora from '../Hora/Hora'
import { useSelector } from 'react-redux'
import { selectRelojGeneral } from '../../redux/reducers/relojGeneralReducer'

function HoraGeneral() {
    const relojGeneral = useSelector(selectRelojGeneral);

    
    return (
        <Hora segundos={relojGeneral.segundos} />
    )
}

export default HoraGeneral

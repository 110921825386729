import { Box, Typography } from "@material-ui/core"
import strPad from '../../utils/strPad'

const Hora = ( {segundos} ) => {
    const minutos = Math.floor( segundos/60 ) 
    const segundos_resto = segundos - (minutos * 60)
    return (
        <>
            <Typography>{ strPad("00",minutos,'l') }</Typography>:<Typography>{ strPad("00",segundos_resto,'l') }</Typography>
        </>
    )
}

export default Hora;